<template>
  <div class="home">
    <zxing @getScanResult="getResult" />
  </div>
</template>

<script>
import zxing from '../components/zxing.vue'

export default {
  components: { zxing },
  name: 'scanSpare',
  mounted () {
    // // const queryParams = this.$myJs.parseUrlParams( code )
    // // const queryParams = this.$myJs.parseUrlParams( `https://192.168.3.112:8080/urlLink?identifyType=1&jobFairId=29&workerId=33&id=19` );
    // const queryParams = this.$myJs.parseUrlParams( `https://192.168.3.112:8080/urlLink?identifyType=2&jobFairId=28&enterpriseId=5&id=20` );
    // localStorage.setItem( 'checkInInfo', JSON.stringify( queryParams ) )
    // this.$router.go( -1 )
  },
  methods: {
    getResult ( code ) {
      // alert( `${ code }` )
      // setTimeout( () => {
      // const queryParams = this.$myJs.parseUrlParams( `https://192.168.3.112:8080/urlLink?identifyType=1&jobFairId=29&workerId=33&id=19` );
      // const queryParams = this.$myJs.parseUrlParams( `https://192.168.3.112:8080/urlLink?identifyType=2&jobFairId=28&enterpriseId=5&id=20` );
      const queryParams = this.$myJs.parseUrlParams( code )
      localStorage.setItem( 'checkInInfo', JSON.stringify( queryParams ) )
      this.$router.go( -1 )
      // }, 500 )
    }
  }
}
</script>

<style lang="less">
</style>
